import { delEte, get, post, put, guessMessage } from '../fetch-extension';
import fetch from 'isomorphic-fetch';

import config from '../../config';
import type { LovenseVibrateData } from '@/ontology/lovense';
import { fetchWithTimeout } from '@/utils';

export async function getAuthenticationToken(performerId: number) {
    const devToken = config.lovenseToken ?? 'sQ5LmMr+bSHFGHJHn9HzyqX1/pKRI4+1B6C3p9hDGpLH242LcA38QdHZbPJ6rjox';
    const uid = performerId;
    const postData = {
        token: devToken,
        uid: uid
    };

    const response = await fetch(`https://api.lovense-api.com/api/basicApi/getToken`, {
        method: 'POST',
        headers: {
            'content-type': 'application/json;charset=UTF-8'
        },
        body: JSON.stringify(postData)
    });

    if (!response.ok) {
        return {
            error: {
                code: response.status,
                message: guessMessage(await response.text()) || response.statusText
            }
        };
    }

    const jsonResponse = await response.json();
    if (!jsonResponse || !jsonResponse.data || !jsonResponse.data.authToken) {
        return {
            error: {
                code: jsonResponse.code ?? 0,
                message: jsonResponse.message ?? 'Authentication failed'
            }
        };
    } else {
        return jsonResponse.data.authToken;
    }
}

export async function getSocketUrl(authToken: string) {
    const platform = config.lovensePlatform ?? 'thuis';
    const postData = {
        platform: platform,
        authToken: authToken
    };

    const response = await fetch(`https://api.lovense.com/api/basicApi/getSocketUrl`, {
        method: 'POST',
        headers: {
            'content-type': 'application/json;charset=UTF-8'
        },
        body: JSON.stringify(postData)
    });

    if (!response.ok) {
        return {
            error: {
                code: response.status,
                message: guessMessage(await response.text()) || response.statusText
            }
        };
    }

    const jsonResponse = await response.json();
    if (!jsonResponse || !jsonResponse.data || !jsonResponse.data.socketIoPath || !jsonResponse.data.socketIoUrl) {
        return {
            error: {
                code: jsonResponse.code ?? 0,
                message: jsonResponse.message ?? 'Could not get socket'
            }
        };
    } else {
        return {
            socketIoPath: jsonResponse.data.socketIoPath,
            socketIoUrl: jsonResponse.data.socketIoUrl
        };
    }
}

export async function saveToyRules(performerId: number, rules: any) {
    const { error, result } = await put<any>(`performer/performer_account/${performerId}/performer_services/toys/rules`, { body: rules });

    if (error) {
        return { error };
    }

    return { result: result };
}

export async function deleteToyRules(performerId: number) {
    const { error, result } = await delEte<any>(`performer/performer_account/${performerId}/performer_services/toys/rules`);

    if (error) {
        return { error };
    }

    return { result: result };
}

export async function getToyRules(performerId: number) {
    const { error, result } = await get<any>(`performer/performer_account/${performerId}/performer_services/toys/rules`);

    if (error) {
        return { error };
    }

    return { result: result };
}

export const getToysLocal = async () => {
    try {
        const postData = {
            command: 'GetToys'
        };

        const response = await fetchWithTimeout(`https://127-0-0-1.lovense.club:30010/command`, {
            method: 'POST',
            headers: {
                'content-type': 'application/json;charset=UTF-8'
            },
            body: JSON.stringify(postData)
        });

        if (!response.ok) {
            return {
                error: {
                    code: response.status,
                    message: guessMessage(await response.text()) || response.statusText
                }
            };
        }

        const jsonResponse = await response.json();
        if (!jsonResponse || !jsonResponse.data || !jsonResponse.code) {
            return {
                error: {
                    code: jsonResponse.code ?? 0,
                    message: jsonResponse.message ?? 'Authentication failed'
                }
            };
        } else {
            return jsonResponse;
        }
    } catch (ex) {
        console.log(ex);
        return {
            error: {
                code: 500,
                message: ex
            }
        };
    }
};

export const getToys = async (token: string, performerId: number) => {
    try {
        const postData = {
            token: token,
            uid: `${performerId}`,
            command: 'GetToys',
            apiVer: 1
        };

        const response = await fetch(`https://api.lovense-api.com/api/lan/v2/command`, {
            method: 'POST',
            headers: {
                'content-type': 'application/json;charset=UTF-8'
            },
            body: JSON.stringify(postData)
        });

        if (!response.ok) {
            return {
                error: {
                    code: response.status,
                    message: guessMessage(await response.text()) || response.statusText
                }
            };
        }

        const jsonResponse = await response.json();
        if (!jsonResponse || !jsonResponse.data || !jsonResponse.code) {
            return {
                error: {
                    code: jsonResponse.code ?? 0,
                    message: jsonResponse.message ?? 'Authentication failed'
                }
            };
        } else {
            return jsonResponse;
        }
    } catch (ex) {
        console.log(ex);
        return {
            error: {
                code: 500,
                message: ex
            }
        };
    }
};

export const doToyLocalCommand = async (command: string, action: string | undefined, timeSec: number, toyId?: string, name?: string) => {
    try {
        const postData: any = {
            command: command,
            timeSec: timeSec,
            apiVer: 1
            //stopPrevious: 0 //queue commands
        };

        if (action) {
            postData.action = action;
        }
        if (toyId) {
            postData.toy = toyId;
        }

        if (name) {
            postData.name = name;
        }

        console.log('postdata', postData);
        const response = await fetch(`https://127-0-0-1.lovense.club:30010/command`, {
            method: 'POST',
            headers: {
                'content-type': 'application/json;charset=UTF-8'
            },
            body: JSON.stringify(postData)
        });

        if (!response.ok) {
            return {
                error: {
                    code: response.status,
                    message: guessMessage(await response.text()) || response.statusText
                }
            };
        }

        const jsonResponse = await response.json();
        if (!jsonResponse || !jsonResponse.code || jsonResponse.code !== 200) {
            return {
                error: {
                    code: jsonResponse.code ?? 0,
                    message: jsonResponse.message ?? 'Authentication failed'
                }
            };
        } else {
            return jsonResponse;
        }
    } catch (ex) {
        console.log(ex);
        return {
            error: {
                code: 500,
                message: ex
            }
        };
    }
};
