<script lang="ts" setup>
import { useAlertsStore } from '@/stores/alerts';
import { storeToRefs } from 'pinia';
import { ref } from 'vue';
import { useRouter } from 'vue-router';

const router = useRouter();
const { messages } = storeToRefs(useAlertsStore());

</script>

<template src="./alerts.html"></template>

<style lang="scss">
@import './alerts.scss';
</style>
