import sanitizeHtml from 'sanitize-html';
import type { LocationQueryValue } from 'vue-router';
import placeholder from '@/assets/images/placeholderprofile.jpg';
import config from '@/config';
import platform from 'platform';
import { useAuthStore } from '@/stores/authentication';
import fetch from 'isomorphic-fetch';
import type { LovenseDevice } from './ontology/lovense';

export function getParameterByName(name: string, url?: string) {
    if (!url) url = window.location.href;
    //name = name.replace(/[\[\]]/g, '\\$&');
    //test this
    name = name.replace(/[[\]]/g, '\\$&');
    const regex = new RegExp(`[?&]${name}(=([^&#]*)|&|#|$)`),
        results = regex.exec(url);
    if (!results) return '';
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

export function asInt(routeParam: string[] | string | LocationQueryValue | LocationQueryValue[]): number {
    let toParse: string;
    if (Array.isArray(routeParam)) {
        toParse = routeParam[0] as string;
    } else {
        toParse = routeParam as string;
    }
    return parseInt(toParse);
}

export function samish(one: number, two: number): boolean {
    return Math.trunc(one * 100) == Math.trunc(two * 100);
}

export function minutes(count: number) {
    return count * seconds(60);
}

export function seconds(count: number) {
    return count * 1000;
}

export function numbersOnly(evt: any) {
    evt = evt ? evt : window.event;
    const charCode = evt.which ? evt.which : evt.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode !== 46) {
        evt.preventDefault();
    } else {
        return true;
    }
}

export const bsnCheck = (bsn: string): boolean => {
    if (!bsn) {
        return true;
    }

    if (bsn.length < 8 || bsn.length > 9) {
        return false;
    }

    const numbers = Array.from(String(bsn), Number);
    const lastNumber = numbers[numbers.length - 1];

    if (typeof lastNumber === 'number' && !numbers.includes(NaN)) {
        return (numbers.slice(0, -1).reduce((a, b, i, arr) => b * (arr.length + 1 - i) + a, 0) - lastNumber) % 11 === 0;
    }

    return false;
};

export function ibanCheck(value: any) {
    if (value === undefined || !value.length) {
        return false;
    }
    const rearrange = value.substring(4, value.length) + value.substring(0, 4);
    const alphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split('');
    const alphaMap: any = {};
    const number: any[] = [];

    alphabet.forEach((value, index) => {
        alphaMap[value] = index + 10;
    });

    rearrange.split('').forEach((value: any, index: any) => {
        number[index] = alphaMap[value] || value;
    });

    return modulo(number.join('').toString(), 97) === 1;
}

const modulo = (aNumStr: any, aDiv: any) => {
    var tmp: any = '';
    var i, r;
    for (i = 0; i < aNumStr.length; i++) {
        tmp += aNumStr.charAt(i);
        r = tmp % aDiv;
        tmp = r.toString();
    }
    return tmp / 1;
};

export function setKPI(url: string, parameter?: string) {
    const para = parameter === undefined ? '' : parameter;
    const call = fetch(`${config.BaseUrl}/session/${url}${para}`, {
        credentials: 'include'
    });
    return call;
}

export function logKPI(url: string, parameters?: any) {
    const options: RequestInit = {
        credentials: 'include'
    };
    if (parameters) {
        options.method = 'POST';
        options.body = JSON.stringify(parameters);
    }
    const call = fetch(`${config.BaseUrl}/session/kpi/${url}`, options);
    return call;
}

export function currency(amount?: number) {
    if (!amount) amount = 0;
    return new Intl.NumberFormat('nl-NL', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
    }).format(amount);
}

export function scrollTop() {
    window.scrollTo({ top: 0, behavior: 'smooth' });
}

export function startFromTop() {
    window.scrollTo({ top: 0, behavior: 'auto' });
}

export function getAvatarImage(performer: any, size: string) {
    if (!performer || !performer.avatar) {
        return placeholder;
    }

    return `${config.ImageUrl}pimg/${performer.id}/${size}/${performer.avatar.name}`;
}

export function getAvatar(performer: any, avatar: string) {
    if (performer && performer.avatar) {
        return `${config.ImageUrl}pimg/${performer.performerId}/large/${avatar}`;
    } else {
        return placeholder;
    }
}

export function imageBlur(can: any, blur: any) {
    //blur = blur;
    let canvas = can;
    let ctx = can.getContext('2d');

    let sum = 0;
    let delta = 5;
    let alpha_left = 1 / (2 * Math.PI * delta * delta);
    let step = blur < 3 ? 1 : 2;
    for (let y = -blur; y <= blur; y += step) {
        for (let x = -blur; x <= blur; x += step) {
            let weight = alpha_left * Math.exp(-(x * x + y * y) / (2 * delta * delta));
            sum += weight;
        }
    }
    let count = 0;
    for (let y = -blur; y <= blur; y += step) {
        for (let x = -blur; x <= blur; x += step) {
            count++;
            ctx.globalAlpha = ((alpha_left * Math.exp(-(x * x + y * y) / (2 * delta * delta))) / sum) * blur;
            ctx.drawImage(canvas, x, y);
        }
    }
    ctx.globalAlpha = 1;
}

export function getAvatarInbox(performer: any, avatar: string) {
    if (performer && performer.avatar) {
        return `${config.ImageUrl}pimg/${performer.performerId}/large/${avatar}`;
    } else {
        return placeholder;
    }
}

export function getAvatarPerformerList(performerId: number, avatar: string) {
    if (performerId && avatar) {
        return `${config.ImageUrl}pimg/${performerId}/large/${avatar}`;
    } else {
        return placeholder;
    }
}

export function getSliderImages(performer: any, photoname: string, size: string) {
    return `${config.ImageUrl}pimg/${performer}/${size}/${photoname}`;
}

export function kebabToCamel(kebab: string) {
    return kebab
        .split('_')
        .map((part, index) => (index && part ? part[0].toUpperCase() + part.slice(1) : part))
        .join('');
}

// checks if 'pattern' is a subset of 'message'
// eg match( {id:3, text:"bla"}, {text:"bla"} ) => true
export function match(message: any, pattern: any): boolean {
    for (var prop in pattern) {
        if (!message) {
            return false;
        }
        if (!(prop in message)) {
            return false;
        }

        if (typeof pattern[prop] === 'object' && typeof message[prop] === 'object') {
            //recursive matching. No guards!
            if (!match(message[prop], pattern[prop])) {
                return false;
            }
        } else if (typeof pattern[prop] === 'function') {
            if (!pattern[prop](message[prop])) {
                return false;
            }
        } else if (pattern[prop] != message[prop]) {
            return false;
        }
    }

    return true;
}

export function among(options: any[]) {
    return (what: any) => options.includes(what);
}

//returns all items from from that aren't in what
export function subtract(what: any[], from: any[]): any[] {
    const result = [];
    for (var item of from) {
        if (what.indexOf(item) == -1) {
            result.push(item);
        }
    }

    return result;
}

//randomly picks an item from a list
export function pickOne(from: any[]) {
    if (!from.length) {
        return undefined;
    }
    const ix = Math.floor(Math.random() * from.length);
    return from[ix];
}

export function encodeHTML(s: string) {
    return s
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/"/g, '&quot;')
        .replace(/:([^:]+):/g, '<i>$1</i>');
}

export function sleep(delay: number): Promise<void> {
    return new Promise((resolve, _) => {
        setTimeout(resolve, delay);
    });
}

export function bigBlackPoster() {
    return new Promise<string>((resolve, reject) => {
        const canvas = document.createElement('canvas');
        canvas.width = 2000;
        canvas.height = 2000;

        canvas.toBlob(blob => {
            if (!blob) {
                reject('unblobbable');
            } else {
                const result = URL.createObjectURL(blob);
                console.log(result);
                resolve(result);
            }
        });
    });
}

export function strLength(str: string) {
    var escstr = encodeURIComponent(str);
    var binstr = escstr.replace(/%([0-9A-F]{2})/gi, function (match, hex) {
        var i = parseInt(hex, 16);
        return String.fromCharCode(i);
    });
    return binstr.length;
}

export function justProfile(p: any) {
    return {
        id: p.id,
        age: p.age,
        cupSize: p.cupSize,
        eyeColor: p.eyeColor,
        height: p.height,
        location: p.location,
        nickname: p.nickname,
        performerCountries: p.performerCountries,
        weight: p.weight,
        newsletter: p.newsletter,
        settings: p.settings
    };
}

export function parseLocations(from: string): { country: string; province: string; city: string } {
    const result = {
        country: '',
        province: '',
        city: ''
    };

    if (!from) {
        return result;
    }

    // If location doesn't contain '/'
    if (from.indexOf('/') === -1) {
        result.city = from;
        return result;
    }

    const locations = from.split('/');

    if (locations.length > 0) {
        result.country = locations[0].trim();
    }
    if (locations.length > 1) {
        result.province = (typeof(locations[1].trim()) != "undefined") ? locations[1].trim() : '';
    }
    if (locations.length > 2) {
        result.city = (typeof(locations[2].trim()) != "undefined") ? locations[2].trim() : '';
    }

    return result;
}

export const capitalizeFirstLetter = (str: string): string => {
    return str.replace(/^\w/, c => c.toUpperCase());
};

export const randomNumber = (min: number, max: number): number => {
    return Math.floor(Math.random() * (max - min + 1) + min);
};

export const fetchWithTimeout = async (resource: RequestInfo, options: any = {}) => {
    const { timeout = 2000 } = options;

    const controller = new AbortController();
    const id = setTimeout(() => controller.abort(), timeout);

    const response = await fetch(resource, {
        ...options,
        signal: controller.signal
    });
    clearTimeout(id);

    return response;
};

export const toyImage = (toy: LovenseDevice) => {
    const version = toy.fVersion ?? '';
    const name = toy.toyType?.replace(/[0-9]/g, '').replace(/\s/g, '').toLowerCase();
    return `https://img.thuis.nl/files/toys/${name}.png`;
};

export function sanitize(text:string):string {
    return sanitizeHtml(text, {
        allowedTags: [],
        allowedAttributes: {},
        allowedIframeHostnames: []
      })
}


export const blob2base64 = (blob: Blob, mimeType: any) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => {
            //const dataUrlPrefix = `data:${mimeType};base64,`;
            const dataUrlPrefix = '';
            const base64WithDataUrlPrefix: any = reader.result;
            const base64 = base64WithDataUrlPrefix.replace(dataUrlPrefix, '');
            resolve(base64);
        };
        reader.onerror = reject;
        reader.readAsDataURL(blob);
    });
};