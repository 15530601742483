import { deleteToyRules, doToyLocalCommand, getAuthenticationToken, getSocketUrl, getToyRules, getToysLocal, saveToyRules } from '@/api/lovense';
//import { LovenseSocketClient, type OnlineInformation, type QrInformation } from '@/socket.lovense';
import { useAlertsStore } from '@/stores/alerts';
import { useChatStore } from '@/stores/chat';
import i18n from '@/translations';
import { defineStore } from 'pinia';
import notifications from '@/socket';
import { capitalizeFirstLetter, randomNumber } from '@/utils';
import { paymentInfo } from '@/api/auth';
import type { Performer } from '@/ontology/performer';
import { addRule, checkRules, defaultRules, defaultSpecialRules, fillRules, loadRules, removeRule, saveRules, toggleRules } from './rules';
import { AMOUNT_CREDIT_STEP, DEFAULT_COUNTRY, DEFAULT_SPEED, MAX_AMOUNT_STEP, type State, getCreditAmount, checkDisable, setOffline } from './state';
import { setToyTimer, startCheckLocalTimer, startTimer, stopCheckLocalTimer, stopTimer, stopToyTimer } from './timers';
import { useThrottledRefHistory } from '@vueuse/core';
import { connectLocal, initSocket, getQrCode, disconnectSocket, disconnectLocal, toggleConnection } from './connection';
import type { OnlineInformation, QrInformation } from '@/socket.lovense';
import type { LovenseDevice, LovenseRule, LovenseSpecialRule, LovenseVibrateData, ToyPreset } from '@/ontology/lovense';
import { setCapability } from './toy';
import { handleNotificationToy, stopVibrate, vibrate } from './commands';
import { queueVibrate, run, clearQueue } from './queue';

export const useLovenseStoreV2 = defineStore({
    id: 'lovenseV2',
    state: (): State =>
        ({
            status: 'disconnected',
            socketConnectionInfo: undefined,
            toys: [],
            logs: [],
            heartbeatTimer: undefined,
            performer: undefined,
            debug: false,
            QRurl: undefined,
            canDisable: false,
            hasToys: false,
            timer: 0,
            timerInterval: undefined,
            usePatternMode: true,
            useSpecialPatternMode: true,
            rules: [...defaultRules],
            specialRules: [...defaultSpecialRules],
            amountCreditStep: AMOUNT_CREDIT_STEP,
            isLocal: false,
            localCheckTimer: undefined,
            hasNewClient: false,
            queue: [],
            running: false
        } as State),
    actions: {
        async connect(performer: Performer) {
            try {
                if (this.status !== 'disconnected') {
                    return;
                }

                this.destroy();
                this.status = 'connecting';

                //initial values
                this.toys = [];
                this.queue = [];
                this.hasToys = false;
                this.performer = performer;

                //load rules
                await loadRules(this);

                //subcribe to notification
                notifications.unsubscribeEvent('toy');
                notifications.subscribe('toy', (data: any) => {
                    handleNotificationToy(this, data);
                });

                this.amountCreditStep = await getCreditAmount(performer);

                //check if toys are active
                if (!(await connectLocal(this))) {
                    await initSocket(this);
                }
            } catch (ex) {
                setOffline(this);
                console.log(ex);
            }
        },
        async destroy() {
            if (this.status !== 'disconnected') {
                //stop every thing and reset
                await disconnectSocket(this);
                await disconnectLocal(this);
                console.log('Disconnecting lovense...');
                this.status = 'disconnected';
            }
        },
        async getQR() {
            getQrCode(this);
        },
        async setCapability(device: LovenseDevice, type: string, preset?: ToyPreset) {
            return setCapability(this, device, type, preset);
        },
        async vibrate(data: LovenseVibrateData): Promise<boolean> {
            return await vibrate(this, data);
        },
        async stopVibrate(emptyQueue = false) {
            if (emptyQueue) {
                clearQueue(this);
                stopToyTimer(this);
            }
            return stopVibrate(this);
        },
        async toggleConnection(isLocal: boolean) {
            toggleConnection(this, isLocal);
        },

        /*handleNotificationToy(data: any) {
            if (data.type != 'TOY') {
                return;
            }
            // console.log(data);
            const defaultTimeStep: number = 5;

            const toyId: string = data.toyId ? data.toyId : undefined;
            const timeMultiplier: number = data.value ? +data.value : 1;
            const rule: string = data.ruleId != '' ? data.ruleId : timeMultiplier;
            //TODO
            // Create way to make the toy vibration time variable based on performer custom price.
            // In backend make a custom patterns available
            for (const toy of this.toys) {
                if (toyId) {
                    if (toy.id === toyId && toy.active) {
                        this.vibrate({ toy: toy, timeInSec: defaultTimeStep * timeMultiplier, rule: rule });
                    }
                } else {
                    if (toy.active) {
                        this.vibrate({ toy: toy, timeInSec: defaultTimeStep * timeMultiplier, rule: rule });
                    }
                }
            }

            // Add alert
            const msg = useAlertsStore();
            const text = i18n.global.t('lovense.vibrateNotificiation', { client: data.clientName, time: defaultTimeStep * timeMultiplier });
            msg.openMessage({ content: text, translate: false, class: 'toy' });

            // Add chat message
            const chat = useChatStore();
            chat.handleMessage({ senderType: 'ROLE_TOY', message: text });

            // Add timer to overlay
            setTimer(this, defaultTimeStep * timeMultiplier);
        },*/
        togglePatternMode() {
            toggleRules(this, false);
        },
        toggleSpecialPatternMode() {
            toggleRules(this, true);
        },
        addRule() {
            return addRule(this);
        },
        removeRule(rule: LovenseRule) {
            removeRule(this, rule);
        },
        async saveRules() {
            return saveRules(this);
        }
    },
    getters: {
        lovenseDevices(state: any): LovenseDevice[] {
            return state.toys;
        },
        hasRules(state: any): boolean {
            if (state.toys && state.rules) {
                for (const rule of state.rules) {
                    if (rule.toys.length > 0 && rule.motions.length > 0) {
                        return true;
                    }
                }
            }
            return false;
        },
        isConnected(state: any): boolean {
            return state.status === 'connected';
        },
        amounts(state: any): number[] {
            const amounts = [];
            for (let i = 1; i <= MAX_AMOUNT_STEP; i++) {
                amounts.push(i * state.amountCreditStep);
            }

            return amounts;
        }
    }
});
