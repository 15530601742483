import type { LovenseDevice, LovenseRule, LovenseSpecialRule } from '@/ontology/lovense';
import { MAX_AMOUNT_STEP, MAX_RULES, type State } from './state';
import { deleteToyRules, getToyRules, saveToyRules } from '@/api/lovense';
import { useAlertsStore } from '@/stores/alerts';
import i18n from '@/translations';

export const defaultRules: LovenseRule[] = [
    {
        id: 1,
        toys: [],
        amount: 1,
        reactionTime: 2,
        intensity: 5,
        motions: ['vibrate']
    },
    {
        id: 2,
        toys: [],
        amount: 3,
        reactionTime: 6,
        intensity: 10,
        motions: ['vibrate']
    },
    {
        id: 3,
        toys: [],
        amount: 5,
        reactionTime: 15,
        intensity: 15,
        motions: ['vibrate']
    },
    {
        id: 4,
        toys: [],
        amount: 7,
        reactionTime: 28,
        intensity: 20,
        motions: ['vibrate']
    }
];

export const defaultSpecialRules: LovenseSpecialRule[] = [
    {
        id: 1,
        amount: 4,
        toys: [],
        reactionTime: 10,
        pattern: 'wave',
        checked: true
    },
    {
        id: 2,
        amount: 4,
        toys: [],
        reactionTime: 10,
        pattern: 'pulse',
        checked: true
    },
    {
        id: 3,
        amount: 4,
        toys: [],
        reactionTime: 10,
        pattern: 'earthquake',
        checked: true
    },
    {
        id: 4,
        amount: 4,
        toys: [],
        reactionTime: 10,
        pattern: 'fireworks',
        checked: true
    },
    {
        id: 5,
        amount: 5,
        toys: [],
        reactionTime: 10,
        pattern: 'random',
        checked: true
    }
];

export const loadRules = async (state: State) => {
    state.debug && console.log('[Lovense] Loading rules from server..');
    //get all rules
    if (!state.performer?.id) {
        console.error('[Lovense] loadRules: No performer id set');
        return;
    }

    const resp = await getToyRules(state.performer.id);
    if (resp && resp.result) {
        const ruleInfo = resp.result.rules;
        if (ruleInfo) {
            state.rules = ruleInfo.rules;
            state.useSpecialPatternMode = ruleInfo.hasSpecial;
            console.info('[Lovense] Rules loaded', ruleInfo);
        } else {
            console.info('[Lovense] Unable to load rules');
        }
    }
};

export const saveRules = async (state: State) => {
    if (state.performer?.id) {
        //or there basic or special rules
        if (state.rules.length > 0 || state.specialRules.length > 0) {
            const ruleInfo: any = {
                hasSpecial: state.useSpecialPatternMode,
                hasRules: state.usePatternMode,
                rules: state.rules,
                specialRules: state.specialRules
            };

            //sanity check...
            const resp = await saveToyRules(state.performer?.id, ruleInfo);
            state.debug && console.debug(`[Lovense debug] Saved rules`, resp);
        } else {
            const resp = await deleteToyRules(state.performer.id);
            state.debug && console.debug(`[Lovense debug] Delete rules`, resp);
        }
    } else {
        console.error(`[Lovense] saveRules: No performer id`);
    }
};

export const fillRules = async (state: State) => {
    //fill rules if nothing from api
    if (state.toys && state.toys.length > 0) {
        for (const rule of state.rules) {
            if (rule.id <= defaultRules.length && rule.toys && rule.toys.length === 0) {
                //push first toy
                rule.toys.push(state.toys[0]);
            }
        }
    }
};

export const addRule = (state: State) => {
    if (state.rules.length >= MAX_RULES) {
        //show message
        const msg = useAlertsStore();
        const text = i18n.global.t('lovense.errors.toManyRules');
        msg.openMessage({ content: text, translate: false, class: 'error' });
        return false;
    }

    //empty rule..
    //get last id
    let lastId = 0;
    let lastAmount = 1;
    if (state.rules && state.rules.length > 0) {
        const lastRule = state.rules[state.rules.length - 1];
        lastId = lastRule.id;
        lastAmount = lastRule.amount;
    }

    const rule: LovenseRule = {
        id: lastId + 1,
        toys: [],
        amount: lastAmount < MAX_AMOUNT_STEP ? lastAmount + 1 : lastAmount,
        reactionTime: 10,
        intensity: 20,
        motions: ['vibrate'] //all toys have vibrate
    };

    getMaxReactionTime(rule);
    state.rules.push(rule);

    saveRules(state);
};

export const removeRule = (state: State, rule: LovenseRule) => {
    //Maybe you should be able to remove the default rules ?
    state.debug && console.debug(rule.id, defaultRules.length);
    if (rule.id < defaultRules.length) {
        return false;
    }

    const index = state.rules.findIndex((p: LovenseRule) => p.id === rule.id);
    if (index > -1) {
        state.rules.splice(index, 1);
    }

    saveRules(state);
};

export const checkRules = async (state: State) => {
    //check if the toys are still connected for the rules...
    if (state.usePatternMode || state.useSpecialPatternMode) {
        if (state.toys.length === 0) {
            //console.log('Clear rules...');
            //clear alls toys and disable pattern
            state.rules = [...defaultRules];
            //state.usePatternMode = false;

            state.specialRules = [...defaultSpecialRules];
            //state.useSpecialPatternMode = false;
        } else {
            //console.log('Check rules..', state.rules);

            state.usePatternMode = true;
            state.useSpecialPatternMode = true;

            //load rules

            for (const index in state.rules) {
                if (state.rules[index].toys && state.rules[index].toys.length > 0) {
                    //check if its in the toy list if not remove it
                    for (const toyIndex in state.rules[index].toys) {
                        const toy = state.rules[index].toys[toyIndex];

                        const findIndex = state.toys.findIndex((p: LovenseDevice) => p.id === toy.id);
                        if (findIndex < 0) {
                            //remove toy from rule
                            state.rules[index].toys.splice(+toyIndex, 1);
                        }
                    }
                }
            }
        }

        fillRules(state);
        saveRules(state);
    }
};

export const toggleRules = (state: State, special = false) => {
    if (special) {
        state.useSpecialPatternMode = !state.useSpecialPatternMode;
        state.debug && console.debug('[Lovense debug] Save from special toggle');
    } else {
        state.usePatternMode = !state.usePatternMode;
        if (state.usePatternMode) {
            fillRules(state);
        }

        state.debug && console.debug('[Lovense debug] Save from toggle');
    }

    saveRules(state);
};

export const getMinReactionTime = (rule: LovenseRule) => {
    if (rule.amount) {
        const amount = rule.amount * Math.ceil(rule.amount / 3);

        /*if (rule.reactionTime < amount) {
            rule.reactionTime = amount;
        }*/

        return amount;
    }

    return 1;
};

export const getMaxReactionTime = (rule: LovenseRule) => {
    if (rule.amount) {
        const amount = rule.amount * (Math.ceil(rule.amount / 3) + 1);
        /*if (rule.reactionTime > amount) {
            rule.reactionTime = amount;
        }*/

        return amount;
    }

    return 60;
};

export const setMaxReactionTime = (rule: LovenseRule) => {
    console.log('Set max');
    if (rule.amount) {
        const amount = rule.amount * (Math.ceil(rule.amount / 3) + 1);
        if (rule.reactionTime > amount) {
            rule.reactionTime = amount;
        }

        return amount;
    }

    return 60;
};
