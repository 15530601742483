export const baseApi: string = import.meta.env.MODE == 'test' ? `${import.meta.env.VITE_FullApiUrl}${import.meta.env.VITE_BaseUrl}` : `${import.meta.env.VITE_BaseUrl}`;
export const tokenDate: string = `${Math.floor(Date.now() / 1000)}`;

if (import.meta.env.DEV) {

    function setCookie(name:string, val:string) {

        const date:Date = new Date();
        date.setTime(date.getTime() + (7 * 24 * 60 * 60 * 1000));
        document.cookie = `${name}=${val}; expires=${date.toUTCString()}; path=/`;
    }

    function getCookie(name:string):string|undefined {

        const value:string = "; " + document.cookie;
        const parts:string[] = value.split("; " + name + "=");

        if (parts && parts.length == 2) {
            const p: string | undefined = parts.pop();
            return p?.split(';').shift() || undefined;
        }

        return undefined;
    }

    function randomSFSESSID(length:number = 16) {

        let mask:string     = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
        let result:string   = '';

        for (let i:number = length; i > 0; --i) {
            result += mask[Math.floor(Math.random() * mask.length)];
        }

        return result;
    }

    if (!getCookie('SFSESSID')) {
        setCookie('SFSESSID', randomSFSESSID());
    }
}