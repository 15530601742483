<script setup="" lang="ts">
import { computed, onMounted } from 'vue';
import { RouterView } from 'vue-router';
import Alerts from './components/alerts/alerts.vue';

import { useAuthStore } from '@/stores/authentication';
const authStore = useAuthStore();

</script>

<template>
    <div :class="'theme-' + authStore.getTheme">
        <RouterView />
        <Alerts />
    </div>
</template>