import { asInt } from '@/utils';
import { createRouter, createWebHistory, type Router } from 'vue-router';

import { useAuthStore } from '@/stores/authentication';

export interface Routed {
    router?: Router;
}

const router = createRouter({
    history: createWebHistory(import.meta.env.BASE_URL),
    routes: [
        {
            path: '/',
            component: () => import('../views/page.vue'),
            name: 'home',
            children: [
                {
                    path: '',
                    component: () => import('../views/news/news.vue'),
                    name: 'news',
                    meta: { title: 'news' },
                    props: route => ({
                        forWhom: 'ROLE_PERFORMER',
                        page: asInt(route.query.page) || 1,
                        offset: asInt(route.query.offset) || 0,
                        limit: asInt(route.query.limit) || 5
                    })
                },
                {
                    path: '/profile/:type',
                    component: () => import('../views/profile/profile.vue'),
                    name: 'profile',
                    meta: { title: 'profile' }
                },
                {
                    path: '/earnings/',
                    component: () => import('../views/earnings/earnings.vue'),
                    name: 'earnings',
                    meta: { title: 'earnings' },
                    props: route => ({
                        page: asInt(route.query.page) || 1,
                        offset: asInt(route.query.offset) || 0,
                        limit: asInt(route.query.limit) || 20,
                        date: Date.now(),
                        scope: 'day',
                        service: 'ALL'
                    })
                },
                {
                    path: '/earnings/specifications/',
                    component: () => import('../views/earnings/specs/specs.vue'),
                    name: 'specifications',
                    meta: { title: 'earnings' },
                    props: route => ({
                        page: asInt(route.query.page) || 1,
                        offset: asInt(route.query.offset) || 0,
                        limit: asInt(route.query.limit) || 20
                    })
                },
                {
                    path: '/photos/',
                    component: () => import('../views/photos/photos.vue'),
                    name: 'photos',
                    meta: { title: 'photos' }
                },
                {
                    path: '/new-photo/',
                    component: () => import('../views/grid/grid.vue'),
                    name: 'newPhoto',
                    meta: { title: 'new-photo' }
                },
                {
                    path: '/videos/',
                    component: () => import('../views/videos/videos.vue'),
                    name: 'videos',
                    meta: { title: 'videos' }
                },
                {
                    path: '/personal/',
                    component: () => import('../views/profile/personal/personal.vue'),
                    name: 'personal',
                    meta: { title: 'personal' }
                },
                {
                    path: '/phone/',
                    component: () => import('../views/profile/phone/phone.vue'),
                    name: 'phone',
                    meta: { title: 'phone' }
                },
                {
                    path: '/countries/',
                    component: () => import('../views/profile/countries/countries.vue'),
                    name: 'countries',
                    meta: { title: 'countries' }
                },
                {
                    path: '/vip/',
                    component: () => import('../views/profile/vip/vip.vue'),
                    name: 'vip',
                    meta: { title: 'vip' }
                },
                {
                    path: '/memos/',
                    component: () => import('../views/profile/memos/memos.vue'),
                    name: 'memos',
                    meta: { title: 'memos' },
                    props: route => ({
                        page: asInt(route.query.page) || 1,
                        offset: asInt(route.query.offset) || 0,
                        limit: asInt(route.query.limit) || 20
                    })
                },
                {
                    path: '/inbox/:inboxType?/',
                    component: () => import('../views/inbox/inbox.vue'),
                    name: 'inbox',
                    meta: { title: 'inbox' },
                    props: route => ({
                        page: asInt(route.query.page) || 1,
                        offset: asInt(route.query.offset) || 0,
                        limit: asInt(route.query.limit) || 10
                    })
                },
                {
                    path: '/forum/',
                    component: () => import('../views/forum/forum.vue'),
                    name: 'forum',
                    meta: { title: 'forum' },
                    props: route => ({
                        page: asInt(route.query.page) || 1,
                        offset: asInt(route.query.offset) || 0,
                        limit: asInt(route.query.limit) || 20
                    })
                },
                {
                    path: '/forum/thread/:id/new/',
                    component: () => import('../views/forum/thread/new-thread.vue'),
                    name: 'newThread',
                    meta: { title: 'forum' }
                },
                {
                    path: '/forum/thread/',
                    component: () => import('../views/forum/thread/thread.vue'),
                    name: 'threadSearch',
                    meta: { title: 'forum' },
                    props: route => ({
                        page: asInt(route.query.page) || 1,
                        offset: asInt(route.query.offset) || 0,
                        limit: asInt(route.query.limit) || 20
                    })
                },
                {
                    path: '/forum/thread/:id/',
                    component: () => import('../views/forum/thread/thread.vue'),
                    name: 'thread',
                    meta: { title: 'forum' },
                    props: route => ({
                        page: asInt(route.query.page) || 1,
                        offset: asInt(route.query.offset) || 0,
                        limit: asInt(route.query.limit) || 20
                    })
                },
                {
                    path: '/forum/topic/:id/',
                    component: () => import('../views/forum/topic/topic.vue'),
                    name: 'topic',
                    meta: { title: 'forum' },
                    props: route => ({
                        id: asInt(route.query.id),
                        page: asInt(route.query.page) || 1,
                        offset: asInt(route.query.offset) || 0,
                        limit: asInt(route.query.limit) || 20
                    })
                },
                {
                    path: '/faq/',
                    component: () => import('../views/faq/faq.vue'),
                    name: 'faq',
                    meta: { title: 'faq' }
                },
                {
                    path: '/clients/',
                    component: () => import('../views/clients/clients.vue'),
                    name: 'clients',
                    meta: { title: 'clients' },
                    props: route => ({
                        page: asInt(route.query.page) || 1,
                        offset: asInt(route.query.offset) || 0,
                        limit: asInt(route.query.limit) || 20,
                        order: 'last_contact',
                        search: undefined,
                        startDate: undefined
                    })
                },
                {
                    path: '/clients/:client_id/',
                    component: () => import('../views/clients/client/client.vue'),
                    name: 'client',
                    meta: { title: 'client' },
                    props: route => ({
                        page: asInt(route.query.page) || 1,
                        offset: asInt(route.query.offset) || 0,
                        limit: asInt(route.query.limit) || 5,
                        username: undefined,
                        country: undefined
                    })
                },
                {
                    path: '/toys/',
                    component: () => import('../views/toys/toys.vue'),
                    name: 'toys',
                    meta: { title: 'toys' }
                },
                {
                    path: '/toys/buttplug/',
                    component: () => import('../views/toys/buttplug/buttplug.vue'),
                    name: 'buttplug',
                    meta: { title: 'toys' }
                },
                {
                    path: '/privacy-policy/',
                    component: () => import('../views/textpages/textpages.vue'),
                    name: 'policy',
                    meta: { title: 'policy' }
                },
                {
                    path: '/cookies/',
                    component: () => import('../views/textpages/textpages.vue'),
                    name: 'cookies',
                    meta: { title: 'cookies' }
                },
                {
                    path: '/terms/',
                    component: () => import('../views/textpages/textpages.vue'),
                    name: 'terms',
                    meta: { title: 'terms' }
                },
                {
                    path: '/complaints/',
                    component: () => import('../views/textpages/textpages.vue'),
                    name: 'complaints',
                    meta: { title: 'complaints' }
                },
                {
                    path: '/instructions/',
                    component: () => import('../views/textpages/textpages.vue'),
                    name: 'instructions',
                    meta: { title: 'instructions' }
                },
                {
                    path: '/contact/',
                    component: () => import('../views/textpages/textpages.vue'),
                    name: 'contact',
                    meta: { title: 'contact' }
                }
            ]
        },
        {
            path: '/register/:token',
            component: () => import('../views/register/register.vue'),
            name: 'register'
        },
        {
            path: '/login/',
            component: () => import('../views/login/login.vue'),
            name: 'login'
        }
    ]
});

router.beforeEach(async (to, from, next) => {
    const performer = useAuthStore();
    if (!performer.isLoggedIn() && to.name != 'login' && to.name != 'register') {
        await performer.authenticate();
    }

    //Don't allow access to the login page if the performer is already logged in
    if (to.name === 'login' && performer.isLoggedIn()) {
        next({
            name: 'news'
        });

        return;
    }

    //Don't allow access to forum if performer is blocked
    if ((to.name === 'newThread' || to.name === 'thread' || to.name === 'topic') && !performer.getForumBlocked) {
        next({
            name: 'forum'
        });

        return;
    }

    //If the user goes to any other page besides login, and is not logged in, redirect back to login page
    const validRoute = to.name === 'login' || to.name === 'register' ? false : true;
    if (!performer.isLoggedIn() && validRoute) {
        next({
            name: 'login'
        });

        return;
    }

    next();
});

export default router;
