import { defineStore } from 'pinia';
import type { Routed } from '@/router';
import { startCasting, performerReady, timeout, end } from '@/api/performer';
import { useAuthStore } from './authentication';
import { useModalsStore } from './modals';
import { useChatStore } from './chat';
import { useAlertsStore } from '@/stores/alerts';

import router from '../router';

import { logKPI, setKPI } from '@/utils';
import notifications, { type VideoChatUpdate } from '@/socket';
import { useLovenseStoreV2 } from './lovense/v2';

export interface Stream {
    id: string;
    name: string;
    token: string;
    server: string;
    status: string;
    attempt: number;
}

interface State extends Routed {
    status: string;
    playStream?: Stream;
    publishStream?: Stream;
    clientRequest: boolean;
    clientTime: number;
    clientBlocked: boolean;
    clientData: any;
    clientCam: boolean;
    clientPlayStates: string[];
    performerPeekCount: number;
    performerVoyeurCount: number;
    joinTimeout: any;
}

let serverConfirmed = false;
let clientConfirmed = false;
let timer: any = undefined;

export const useSessionStore = defineStore({
    id: 'session',
    state: (): State => ({
        status: 'idle',
        playStream: undefined,
        publishStream: undefined,
        clientRequest: false,
        clientTime: 0,
        clientBlocked: false,
        clientData: undefined,
        clientCam: false,
        clientPlayStates: [],
        performerPeekCount: 0,
        performerVoyeurCount: 0,
        joinTimeout: Number.NaN
    }),
    actions: {
        initialize() {
            //unsubscribe first..
            notifications.unsubscribeEvent('videoChat');
            notifications.unsubscribeEvent('status_change');
            notifications.unsubscribeEvent('voyeur');
            notifications.unsubscribeEvent('clientstream');

            //subscribe to messages...
            notifications.subscribe('videoChat', this.handleNotification);
            notifications.subscribe('status_change', this.handleNotification);
            notifications.subscribe('voyeur', this.handleNotification);
            notifications.subscribe('clientstream', this.handleNotificationBackcam);
        },
        handleNotificationBackcam(payload: VideoChatUpdate) {
            if (this.status != 'connected') {
                return;
            }

            if (payload.type === 'ACTIVATED') {
                logKPI('pf_camback_notification_received');
                this.clientCam = true;
                return;
            }

            if (payload.type === 'DISCONNECT') {
                this.clientCam = false;
                return;
            }
        },
        handleNotification(payload: VideoChatUpdate) {
            const { toggleModal } = useModalsStore();

            if (payload.type === 'CANCEL_REQUEST' && this.clientRequest) {
                this.clearRequest();
                return;
            }

            if (payload.type === 'REQUEST' && !this.clientRequest) {
                if (this.active) {
                    console.log('Sessie is active...');
                } else {
                    console.error('Sessie is niet active!');
                    return;
                }

                setKPI('performer_received_request');

                this.setRequest(payload);
                const typeRequest = payload.sessionType === 'VIPVIDEO' ? 'viprequest' : 'request';
                toggleModal('session', typeRequest);
                return;
            }

            if (payload.type === 'DISCONNECT') {
                const msg = useAlertsStore();

                if (!this.clientRequest) {
                    const reasons = ['DISCONNECT', 'CLICK', 'BROKE'];
                    if (reasons.indexOf(payload.message) > -1) {
                        msg.openMessage({ content: `overlay.alerts.disconnect.${payload.message}`, class: 'error' });
                    } else {
                        msg.openMessage({ content: 'overlay.alerts.disconnect.DEFAULT', class: 'error' });
                    }

                    //stop all vibrations
                    const lovense = useLovenseStoreV2();
                    if (lovense) {
                        lovense.stopVibrate(true); //also empty queue
                    } else {
                        console.error('SocketDisconnect: could not initialize lovense store');
                    }
                }

                this.clearRequest();

                return;
            }

            if (payload.type === 'START_TIMER' || payload.type === 'START_TIMER_DEVICE') {
                if (payload.type === 'START_TIMER') {
                    serverConfirmed = true;

                    this.timeoutStart();
                }

                if (payload.type === 'START_TIMER_DEVICE') {
                    clientConfirmed = true;
                }

                if (serverConfirmed && clientConfirmed) {
                    serverConfirmed = false;
                    clientConfirmed = false;

                    this.timeoutStop();
                    this.startTimer();
                }
            }

            if (payload.type === 'PEEK_DISCONNECT' && this.active) {
                //clear toy queue from peeker
                console.log('Remove queue from peeker', payload);
            }

            if (payload.type === 'PEEK_ACTIVATED' || payload.type === 'PEEK_DISCONNECT') {
                this.performerPeekCount = payload.value;
                return;
            }

            if (payload.type === 'VOYEUR_ACTIVATED' || payload.type === 'VOYEUR_DISCONNECT') {
                this.performerVoyeurCount = payload.value;
                return;
            }
        },

        async initiateStream() {
            const performer = useAuthStore().account;
            const { error: initiateError, result } = await startCasting(performer.id!);

            if (initiateError) {
                this.setStatus('error');
                return initiateError;
            }

            if (!result) {
                throw new Error('Impossible');
            }

            this.$patch({
                publishStream: {
                    name: result.publishStream,
                    token: result.publishToken,
                    server: result.publishWowza,
                    status: 'idle',
                    attempt: 1
                },
                playStream: {
                    id: result.id,
                    name: result.playStream,
                    token: result.playToken,
                    server: result.playWowza,
                    status: 'idle',
                    attempt: 1
                }
            });

            this.setStatus('active');
        },

        async endStream() {
            this.setStatus('idle');

            const performer = useAuthStore().account;
            await end({ performerId: performer.id, clientId: 0 });

            this.$patch({
                publishStream: undefined,
                playStream: undefined
            });
        },

        async acceptRequest() {
            const { error: initiateError, result } = await performerReady({ clientId: this.clientData.id });

            if (initiateError) {
                this.setStatus('error');
                return initiateError;
            }

            if (!result) {
                throw new Error('Impossible');
            }

            setKPI('performer_clicked_accept');

            this.setStatus('connected');
            this.$patch({ clientRequest: false });
        },

        async stop1on1() {
            const performer = useAuthStore().account;
            const { error: initiateError, result } = await end({ performerId: performer.id, clientId: this.clientData.id, blocked: this.clientBlocked });

            if (!result) {
                throw new Error('Impossible');
            }

            this.clearRequest();
        },

        declineRequest() {
            const performer = useAuthStore().account;
            notifications.sendEvent({
                event: 'videoChat',
                receiverType: 'ROLE_CLIENT',
                receiverId: this.clientData.id,
                content: {
                    _stateChange: 'REJPERF',
                    performerId: performer.id,
                    clientId: this.clientData.id,
                    type: 'RESPONSE',
                    value: false
                }
            });
        },

        setRequest(payload: any) {
            const data = payload.data ? payload.data.split('|') : '';

            this.$patch({
                clientRequest: true,
                clientBlocked: false,
                clientData: {
                    id: payload.clientId,
                    name: payload.value,
                    sessionType: payload.sessionType,
                    type: data.length > 0 ? data[0] : ''
                }
            });
        },

        async timeout() {
            const performer = useAuthStore().account;
            const { error: initiateError, result } = await timeout({ performerId: performer.id, clientId: this.clientData.id });

            if (!result) {
                throw new Error('Impossible');
            }
        },

        async blockClient() {
            this.clientBlocked = true;
            await this.stop1on1();
        },

        timeoutStart() {
            this.joinTimeout = setTimeout(() => {
                this.timeout();
                this.clearRequest();
            }, 59 * 1000);
        },

        timeoutStop() {
            clearTimeout(this.joinTimeout);
        },
        clearRequest() {
            this.setStatus('active');
            // Reset session store
            this.$patch({
                clientRequest: false,
                clientBlocked: false,
                clientData: undefined,
                clientTime: 0,
                clientPlayStates: []
            });

            // Reset chat store
            const chat = useChatStore();
            chat.clearMessages();
        },

        startTimer() {
            this.clientTime += 1000;
            if (this.status === 'connected') {
                setTimeout(() => this.startTimer(), 1000);
            }
        },

        setStatus(value: any) {
            this.status = value;

            notifications.sendLocalEvent('status_change', {
                sender: 'cam',
                value
            });
        },

        publishStatusChange(value: string, error?: string) {
            //console.log('publish status', value, error)

            // Terminate overlay when Janus quits!
            if (['destroying', 'destroying_janus'].includes(value)) {
                // Reset data states
                this.clearRequest();
                this.setStatus('idle');
            }
        },

        playStreamStatusChange(value: string, error?: string) {
            // Set error KPI
            if (value == 'error' && error) {
                logKPI('pf_camback_error', { message: error });
            }

            // Set active/connected KPI
            this.clientPlayStates = [value].concat(this.clientPlayStates);
            ['connected', 'active'].some(status => {
                if (status !== value) {
                    return false;
                }

                if (this.clientPlayStates.filter((playState: any) => playState === status).length > 1) {
                    return false;
                }
                logKPI(`pf_camback_${status}`);
                return true;
            });
        }
    },
    getters: {
        getSessionName(state: any) {
            const name = state.clientData.name;
            const username = name.substring(name.indexOf('|') + 1);
            const country = name.slice(0, 2);
            return `<i class="flag flag-${country}"></i>${username.substring(0, 25)}`;
        },
        getChatName(state: any) {
            const name = state.clientData.name;
            return name.substring(name.indexOf('|') + 1);
        },
        connected(state: any) {
            return state.status === 'connected';
        },
        active(state: any) {
            return state.status === 'connected' || state.status === 'active';
        }
    }
});
