import { deviceFingeringSupported, devicePresetSupported, devicesSupported, deviceRotateSupported, deviceSuctionSupported, deviceThrustingSupported, type LovenseClient, type LovenseDevice, type ToyPreset } from '@/ontology/lovense';
import { checkDisable, DEFAULT_SPEED, type State } from './state';
import { checkRules } from './rules';

export const setClient = (state: State, client: LovenseClient) => {
    state.debug && console.log(`Setting active client`, client);

    if (client && state.status === 'connected') {
        //stop timer for local connect
        if (state.client !== undefined && state.client.deviceCode !== client.deviceCode && !state.hasNewClient) {
            console.warn('Device already connected', state.client.deviceCode, client.deviceCode);
            //more then one client connected but just show it one time

            return;
        }

        //check if client exists
        state.client = client;

        state.debug && console.debug('client is', state.client);

        //todo make diffs...
        //this.toys = [];
        //remove all toys who are not in the list any more
        for (const key in state.toys) {
            const device = state.toys[key];
            const toy = state.client.toyList.find((p: any) => p.id === device.id);
            if (!toy || !toy.connected) {
                state.toys.splice(+key, 1);
            }
        }

        //remove everything from toys who is not in the list anymore
        for (const device of state.client.toyList) {
            const toy = device as LovenseDevice;
            const internalToy = state.toys.find((p: LovenseDevice) => p.id === toy.id);
            if (internalToy) {
                state.debug && console.debug('Toy already exists.. (update) some information');
                continue;
            }

            toy.motions = [];
            toy.vibrate = true;
            toy.motions.push('vibrate');
            toy.speed = DEFAULT_SPEED;
            toy.rotationSpeed = DEFAULT_SPEED;
            toy.pumpSpeed = 1;
            toy.pump = true;
            toy.active = true;

            if (!toy.toyType || !devicesSupported.includes(toy.toyType.toLowerCase())) {
                console.error(`Toy ${toy.name} ${toy.toyType} not supported!`);
                continue;
            }

            toy.rotate = deviceRotateSupported.includes(toy.toyType) ? true : undefined;
            if (toy.rotate !== undefined) {
                toy.motions.push('rotate');
            }

            toy.pump = toy.toyType.toLowerCase() === 'max' ? true : undefined;
            if (toy.pump !== undefined) {
                toy.motions.push('pump');
            }
            //todo disabled for now
            // Vibrate the toy under pulse pattern, the running time is 9 seconds.
            toy.pattern = undefined;
            toy.patternRule = 'V:1;F:vr;S:1000#';
            toy.patternStrength = '20;5;10';

            toy.preset = devicePresetSupported.includes(toy.toyType) ? false : undefined;
            toy.presetKind = 'pulse';
            if (toy.preset !== undefined) {
                toy.motions.push('preset');
            }

            toy.thrusting = deviceThrustingSupported.includes(toy.toyType) ? false : undefined;
            if (toy.thrusting !== undefined) {
                toy.motions.push('thrusting');
            }
            toy.thrustingSpeed = DEFAULT_SPEED;

            toy.fingering = deviceFingeringSupported.includes(toy.toyType) ? false : undefined;
            if (toy.fingering !== undefined) {
                toy.motions.push('fingering');
            }
            toy.fingeringSpeed = DEFAULT_SPEED;

            toy.suction = deviceSuctionSupported.includes(toy.toyType) ? false : undefined;
            toy.suctionSpeed = DEFAULT_SPEED;
            if (toy.suction !== undefined) {
                toy.motions.push('suction');
            }

            if (toy.connected) {
                state.toys.push(toy);
            }
        }

        checkDisable(state);
        checkRules(state);
        state.hasNewClient = false;
    }
};

export const setCapability = (state: State, device: LovenseDevice, type: string, preset?: ToyPreset) => {
    const toy: LovenseDevice | undefined = state.toys.find((p: LovenseDevice) => p.id === device.id);
    if (!toy) {
        throw Error(`[lovense] setCapablity: Could not find toy ${device.name} id: ${device.id} `);
    }
    const index: number = state.toys.findIndex((p: LovenseDevice) => p.id === device.id);

    switch (type) {
        case 'pattern':
            if (toy.pattern !== undefined) {
                toy.pattern != toy.pattern;
            }
            break;
        case 'preset':
            if (toy.preset !== undefined && !preset) {
                toy.preset = !toy.preset;
                if (toy.preset == true) {
                    //disable others
                    if (toy.vibrate !== undefined) toy.vibrate = false;
                    if (toy.rotate !== undefined) toy.rotate = false;
                    if (toy.pump !== undefined) toy.pump = false;
                    if (toy.pattern !== undefined) toy.pattern = false;
                    if (toy.thrusting !== undefined) toy.thrusting = false;
                    if (toy.fingering !== undefined) toy.fingering = false;
                    if (toy.suction !== undefined) toy.suction = false;
                } else {
                    //enable at least vibrate
                    toy.vibrate = true;
                }
            }

            if (preset) {
                toy.presetKind = preset;
            }

            break;
        case 'vibrate':
            if (toy.vibrate !== undefined) {
                toy.vibrate = !toy.vibrate;

                if (toy.vibrate && toy.preset) {
                    toy.preset = false;
                }
            }
            break;
        case 'rotate':
            if (toy.rotate !== undefined) {
                toy.rotate = !toy.rotate;

                if (toy.rotate && toy.preset) {
                    toy.preset = false;
                }
            }
            break;
        case 'pump':
            if (toy.pump !== undefined) {
                toy.pump = !toy.pump;
                if (toy.pump && toy.preset) {
                    toy.preset = false;
                }
            }
            break;
        case 'thrusting':
            if (toy.thrusting !== undefined) {
                toy.thrusting = !toy.thrusting;

                if (toy.thrusting && toy.preset) {
                    toy.preset = false;
                }
            }
            break;
        case 'fingering':
            if (toy.fingering !== undefined) {
                toy.fingering = !toy.fingering;

                if (toy.fingering && toy.preset) {
                    toy.preset = false;
                }
            }
            break;
        case 'suction':
            if (toy.suction !== undefined) {
                toy.suction = !toy.suction;

                if (toy.suction && toy.preset) {
                    toy.preset = false;
                }
            }
            break;
        case 'active':
            toy.active = !toy.active;
            break;
    }
    state.toys[index] = toy;

    checkDisable(state);
};
