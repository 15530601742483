import { doToyLocalCommand } from '@/api/lovense';
import type { State } from './state';
import type { LovenseDevice, LovenseRule, LovenseSpecialRule, LovenseVibrateData } from '@/ontology/lovense';
import { useAlertsStore } from '@/stores/alerts';
import { capitalizeFirstLetter, randomNumber } from '@/utils';
import i18n from '@/translations';
import { useChatStore } from '@/stores/chat';
import { setToyTimer } from './timers';
import { useLovenseStoreV2 } from '@/stores/lovense/v2';
import { queueVibrate, run } from './queue';

export const handleNotificationToy = async (state: State, data: any) => {
    const toyStore = useLovenseStoreV2();
    if (data.type != 'TOY') {
        return;
    }

    const defaultTimeStep: number = 5;

    const toyId: string = data.toyId ? data.toyId : undefined;
    const timeMultiplier: number = data.value ? +data.value : 1;

    // In backend make a custom patterns available
    if (data.rule) {
        queueVibrate(state, data);
        return;
    } else {
        if (toyId) {
            const toy = state.toys.find(p => p.id === toyId);
            if (toy) {
                queueVibrate(state, { toy: toy, timeInSec: defaultTimeStep * timeMultiplier, clientName: data.clientName });
            } else {
                throw Error(`Could not find toy with id: ${toyId}`);
            }
        } else {
            queueVibrate(state, { toys: state.toys, timeInSec: defaultTimeStep * timeMultiplier, clientName: data.clientName });
        }
    }
};

export const sendNotification = (state: State, data: any, time: number, pattern?: string) => {
    const msg = useAlertsStore();

    let text = i18n.global.t('lovense.vibrateNotificiation', { client: data.clientName, time: time });
    if (pattern) {
        text = i18n.global.t('lovense.vibrateNotificiationPattern', { client: data.clientName, pattern: pattern, time: time });
    }

    msg.openMessage({ content: text, translate: false, class: 'toy' });

    // Add chat message
    const chat = useChatStore();
    chat.handleMessage({ senderType: 'ROLE_TOY', message: text });

    // Add timer to overlay
    //setToyTimer(state, time);
};

export const stopVibrate = async (state: State): Promise<boolean> => {
    state.debug && console.info('Stopping toy vibration');
    sendCommand(state, 'Function', undefined, '0', 'stop');
    return true;
};

export const vibrate = async (state: State, data: LovenseVibrateData): Promise<boolean> => {
    if (!state.isLocal && (!state.socket || !state.socket.isConnected)) {
        throw Error(`Socket is not connected`);
    }

    const msg = useAlertsStore();

    if (!data) {
        console.error(`TestVibrate: Parameter data can not be ${data}`);
        return false;
    }

    //pattern mode code
    if ((state.usePatternMode || state.useSpecialPatternMode) && data.rule) {
        console.log('Going for the pattern...');
        return vibratePattern(state, data);
    }

    if (!data.toy) {
        //do all
        msg.openMessage({ content: 'toys.errors.deviceDisabled', class: 'error' });
        return false;
    }

    const toy: LovenseDevice | undefined = state.toys.find((p: LovenseDevice) => p.id === data.toy?.id);

    if (!toy || !toy.active) {
        console.log('Not active!', toy);
        return false;
    }

    //Normal mode ..make command
    let command = 'Function';
    let action = undefined;
    let name = undefined;
    if (toy.vibrate) {
        action = `Vibrate:${toy.speed}`;
    }

    if (toy.rotate) {
        if (action) {
            action += `,Rotate:${toy.rotationSpeed}`;
        } else {
            action = `Rotate:${toy.rotationSpeed}`;
        }
    }

    if (toy.pump) {
        if (action) {
            action += `,Pump:${toy.pumpSpeed}`;
        } else {
            action = `Pump:${toy.pumpSpeed}`;
        }
    }

    if (toy.pattern) {
        //do something else
        command = 'Pattern';
        action = `V:1;F:vr;S:1000#`; //defined pattern (todo)
    }

    if (toy.preset) {
        command = 'Preset';
        name = toy.presetKind;
        action = undefined;
    }

    //sendNotification(state, data, data.timeInSec);
    sendCommand(state, command, toy.id, `${data.timeInSec}`, action, name);
    return true;
};

export const vibratePattern = async (state: State, data: LovenseVibrateData): Promise<boolean> => {
    const msg = useAlertsStore();
    state.debug && console.log(`using pattern mode ${data.rule}`);
    let command = 'Function';
    let action = undefined;
    let name = undefined;

    if (data.rule === undefined) {
        throw new Error('Rule not defined in data');
    }

    if (data.special === 'true') {
        console.log('using special');
        const index = state.specialRules.findIndex((p: LovenseSpecialRule) => {
            return p.id === (data.rule ? +data.rule : -1);
        });

        if (index > -1) {
            const rule = state.specialRules[index];
            command = 'Preset';
            name = rule.pattern;
            if (rule.pattern === 'random') {
                const patterns = ['pulse', 'wave', 'fireworks', 'earthquake'];
                const randNr = randomNumber(1, patterns.length) - 1;
                name = patterns[randNr];
            }

            //sendNotification(state, data, rule.reactionTime, name);
            sendCommand(state, command, undefined, `${rule.reactionTime}`, action, name);
            return true;
        }
    } else {
        console.log('using not special');
        //get rules based on amount or as close as possible...
        const index = state.rules.findIndex((p: LovenseRule) => {
            return p.id === (data.rule ? +data.rule : -1);
        });

        if (index > -1) {
            const rule: LovenseRule = state.rules[index];
            let first = true;
            if (rule.motions === undefined) {
                return false;
            }

            for (const motion of rule.motions) {
                if (first) {
                    action = `${capitalizeFirstLetter(motion)}:${rule.intensity}`;
                    first = false;
                } else {
                    action += `,${capitalizeFirstLetter(motion)}:${rule.intensity}`;
                }
            }

            for (const toyRule of rule.toys) {
                //this.debug &&
                console.log(`command ${command} action: ${action} ${toyRule.name}`, toyRule);

                //@TODO check if toy can do command (but maybe it ignores it....)
                sendCommand(state, command, toyRule.id, `${rule.reactionTime}`, action, name);
            }

            //sendNotification(state, data, rule.reactionTime);

            return true;
        }
    }

    return false;
};

export const sendCommand = async (state: State, command: string, id: string | string[] | undefined, timeInSec: string, action?: string, name?: string) => {
    const commandObject: any = {
        command: command,
        timeSec: +timeInSec,
        apiVer: 1
        //stopPrevious: 0 // queu events do not stop the previous command
    };

    if (id) {
        commandObject.toy = id;
    }

    if (action) {
        commandObject.action = action;
    }

    if (name) {
        commandObject.name = name;
    }

    if (state.isLocal) {
        const resp = await doToyLocalCommand(commandObject.command, commandObject.action, commandObject.timeSec, commandObject.toy, commandObject.name);
        state.debug && console.log(resp);
    } else {
        if (!state.socket) {
            throw Error(`[Lovense] Send command: socket not connected!`);
        }

        state.socket.sendCommand(commandObject);
    }
};
