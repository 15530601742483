import { paymentInfo } from '@/api/auth';
import type { LovenseClient, LovenseDevice, LovenseRule, LovenseSpecialRule, LovenseVibrateData } from '@/ontology/lovense';
import type { Performer } from '@/ontology/performer';
import type { LovenseSocketClient, OnlineInformation } from '@/socket.lovense';
import { stopCheckLocalTimer } from './timers';

export const DEFAULT_SPEED = 5;
export const DEFAULT_MAX_SPEED = 20;
export const DEFAULT_MIN_SPEED = 1;
export const MAX_RULES = 6;

export const MAX_AMOUNT_STEP = 12;
export const AMOUNT_CREDIT_STEP = 40;
export const DEFAULT_COUNTRY = 'nl';

export type ClientState = 'disconnected' | 'connecting' | 'connected' | 'waiting_for_qr';

export interface State {
    status: ClientState;
    socketConnectionInfo?: { socketIoUrl: string; socketIoPath: string };
    socket?: LovenseSocketClient;
    client?: LovenseClient;
    heartbeatTimer: any;
    performer?: Performer;
    toys: LovenseDevice[];
    logs: { event: string; [rest: string]: any }[];
    debug: boolean;
    QRurl: any;
    canDisable: boolean;
    hasToys: boolean;
    timer: number;
    timerInterval: any;
    usePatternMode: boolean;
    useSpecialPatternMode: boolean;
    rules: LovenseRule[];
    specialRules: LovenseSpecialRule[];
    amountCreditStep: number;
    isLocal: boolean;
    localCheckTimer: any;
    hasNewClient: boolean;
    queue: LovenseVibrateData[];
    running: boolean;
}

export const getCreditAmount = async (performer: Performer): Promise<number> => {
    //get tarif
    const { result, error } = await paymentInfo();

    if (error) {
        return AMOUNT_CREDIT_STEP;
    }

    const country = performer.country ? performer.country : DEFAULT_COUNTRY;
    const toyFee = result[country].TOY ? result[country].TOY : AMOUNT_CREDIT_STEP;

    return toyFee;
};

export const checkDisable = (state: State) => {
    state.canDisable = state.toys.filter((p: LovenseDevice) => p.active === true).length > 1;
    state.hasToys = state.toys.length > 0;
};

export const setOnline = (state: State, online: OnlineInformation) => {
    try {
        state.debug && console.debug('Set online', online);
        if (online.status === 1) {
            state.status = 'connected';
            stopCheckLocalTimer(state);
        } else if (online.status === 0 && state.status === 'connected' && state.client?.platform === 'ios') {
            //setOffline(state);
        }
    } catch (ex) {
        console.error(ex);
    }
};

export const setStatus = (state: State, online: OnlineInformation) => {
    try {
        state.debug && console.debug('Set status', online);
        if (online.status === 1) {
            state.status = 'connected';
            stopCheckLocalTimer(state);
            //new client
            state.hasNewClient = true;
        } else if (online.status === 0 && state.status === 'connected') {
            setOffline(state);
        }
    } catch (ex) {
        console.error(ex);
    }
};

export const setOffline = (state: State) => {
    state.status = 'disconnected';
    state.client = undefined;
    state.toys = [];
    checkDisable(state);
};
