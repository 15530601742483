import { connectLocal } from './connection';
import { checkDisable, type State } from './state';

export const startTimer = async (state: State, heartBeatIntervalSec: number = 5) => {
    if (!state.heartbeatTimer) {
        state.heartbeatTimer = setInterval(async () => {
            try {
                if (state.status === 'connected' || state.status === 'waiting_for_qr') {
                    await connectLocal(state);
                }
            } catch (ex) {
                console.error(ex);
            }
        }, heartBeatIntervalSec * 1000);
    }
};

export const stopTimer = async (state: State) => {
    if (state.heartbeatTimer) {
        state.status = 'disconnected';
        state.client = undefined;
        state.toys = [];
        checkDisable(state);
        clearInterval(state.heartbeatTimer);
        state.heartbeatTimer = undefined;
        state.isLocal = false;
    }
};

export const startCheckLocalTimer = async (state: State, interval: number) => {
    if (!state.localCheckTimer) {
        state.localCheckTimer = setInterval(async () => {
            try {
                if (state.status === 'waiting_for_qr') {
                    await connectLocal(state);
                }
            } catch (ex) {
                console.error(ex);
            }
        }, interval * 1000);
    }
};

export const stopCheckLocalTimer = async (state: State) => {
    if (state.localCheckTimer) {
        clearInterval(state.localCheckTimer);
        state.localCheckTimer = undefined;
    }
};

export const setToyTimer = (state: State, time: number) => {
    state.timer = state.timer + time;
    if (!state.timerInterval) {
        state.timerInterval = setInterval(async () => {
            state.timer = state.timer - 1;
            if (state.timer <= 0) {
                clearInterval(state.timerInterval);
                (state as any).$patch({
                    timerInterval: undefined,
                    timer: 0
                });
            }
        }, 1000);
    }
};

export const stopToyTimer = (state: State) => {
    if (state.timerInterval) {
        clearInterval(state.timerInterval);
        state.timerInterval = undefined;
    }

    state.timer = 0;
};
