import i18n from './translations';
import { startOfWeek, endOfWeek, subWeeks, format } from 'date-fns';

const filters = {
    currency(credits: number) {
        if (isNaN(credits)) {
            return '-';
        }
        return `€ ${(credits / 100).toFixed(2)}`;
    },
    currencyNon(credits: number) {
        if (isNaN(credits)) {
            return '-';
        }
        return `€ ${credits}`;
    },
    shortCurrency(credits: number) {
        if (isNaN(credits)) {
            return '-';
        }
        return `€${(credits / 100).toFixed(0)}`;
    },
    niceCredits(credits: number) {
        let cc = Math.floor(credits);
        return cc.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');
    },
    date(value: number){
        const date = new Date(value * 1000);
    
        return formatDate(date);
    },
    procent(percentage: number){
        const percentageFull = Math.floor((percentage * 100));
    
        return `${percentageFull}%`;
    },
    truncate(input: string, length: number){
        return input.slice(0, length);
    },
    shortDate(value: number) {
        const date = new Date(value * 1000);
        return formatShortDate(date);
    },
    sessionTime: function(value: number){
        const date = new Date(value);
        const leadingZero = (num: number) => `0${num}`.slice(-2);
        return `${date.getUTCHours() > 0 ? `${leadingZero(date.getUTCHours())}:` : ''}${leadingZero(date.getUTCMinutes())}:${leadingZero(date.getUTCSeconds())}`;
    },
    countdown(seconds: number) {
        const set = {day: 86400, hour: 3600, min: 60};
    
        const days = seconds / set.day;
        const hours = seconds / set.hour;
        const minutes = seconds / set.min;
    
        const total = days <= 1 ? (hours <= 1 ? Math.round(minutes) : Math.round(hours)) : Math.round(days);
        const totalLabel = days <= 1 ? (hours <= 1 ? i18n.global.t('profile.minutes') : i18n.global.t('profile.hours')) : i18n.global.t('profile.days');
        return `${total} ${totalLabel}`;
    },
    username(username: string){
        return username.indexOf('annon') > -1 ? i18n.global.t('general.anonymous') : username;
    },
    fancyWeek(stamp:number){
        const value = new Date( stamp );
        const now = new Date();
        if ( isInWeek( value, now ) ){
            return i18n.global.t('general.fancyWeek.this-week');
        }
    
        const lastWeek = subWeeks( now, 1);
        if ( isInWeek(value, lastWeek) ){
            return i18n.global.t('general.fancyWeek.last-week');
        }
    
        const twoWeeksAgo = subWeeks( now, 2);
        if ( isInWeek(value, twoWeeksAgo) ){
            return i18n.global.t('general.fancyWeek.two-weeks-ago');
        }
    
        return `${format(startOfWeek(value, {weekStartsOn:1}), 'dd/MM')} ${i18n.global.t('general.fancyWeek.until')} ${format(endOfWeek(value, {weekStartsOn:1}), 'dd/MM')}`;
    }
};

export default filters;

const leadingZero = (num: number) => `0${num}`.slice(-2);

function formatDate(date: Date): string {
    return `${leadingZero(date.getDate())}-${leadingZero(date.getMonth() + 1)}-${date.getFullYear()} | ${leadingZero(date.getHours())}:${leadingZero(date.getMinutes())}`;
}

function formatShortDate(date: Date): string {
    return `${leadingZero(date.getDate())}-${leadingZero(date.getMonth() + 1)}-${date.getFullYear()}`;
}

function isInWeek( date:Date, dateInTheWeek:Date){
    const start = startOfWeek( dateInTheWeek, { weekStartsOn:1 } );
    const end = endOfWeek( dateInTheWeek, { weekStartsOn:1 } );
    return isBetween(date, start, end);
}

function isBetween(date:Date, start:Date, end:Date){
    return ( start.getTime() <= date.getTime() ) && ( date.getTime() <= end.getTime() );
}