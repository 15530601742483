import type { LovenseRule, LovenseSpecialRule, LovenseVibrateData } from '@/ontology/lovense';
import type { State } from './state';
import { sendNotification, stopVibrate, vibrate } from './commands';
import { sleep } from '@/utils';
import { setToyTimer } from './timers';

export const queueVibrate = (state: State, command: LovenseVibrateData) => {
    console.log('Add to queu', command);
    state.queue.push(command);
    //add time
    //get rule and duration

    run(state);
};

export const clearQueue = (state: State) => {
    state.queue = [];
};

export const runQueue = async (state: State, resolve: (value: unknown) => void, reject: (reason?: any) => void) => {
    try {
        if (state.running) {
            return;
        }

        const command = state.queue.shift();
        if (command) {
            state.running = true;
            if (Array.isArray(command.toys)) {
                for (const toy of command.toys) {
                    if (toy.active) {
                        command.toy = toy;
                        vibrate(state, command);
                    }
                }
            } else {
                await vibrate(state, command);
            }

            let sleepTime = command.timeInSec;
            let pattern = undefined;
            //get rule...
            if (command.rule) {
                if (command.special === 'true') {
                    const index = state.specialRules.findIndex((p: LovenseSpecialRule) => {
                        return p.id === (command.rule ? +command.rule : -1);
                    });

                    if (index > -1) {
                        const rule = state.specialRules[index];
                        pattern = rule.pattern as string;
                        sleepTime = rule.reactionTime;
                    }
                } else {
                    const index = state.rules.findIndex((p: LovenseRule) => {
                        return p.id === (command.rule ? +command.rule : -1);
                    });

                    if (index > -1) {
                        const rule: LovenseRule = state.rules[index];
                        sleepTime = rule.reactionTime;
                    }
                }
            }

            if (sleepTime) {
                setToyTimer(state, sleepTime);
                sendNotification(state, command, sleepTime, pattern);
                //console.log('sleeping', sleepTime);
                await sleep(sleepTime * 1000);
            }
            //state.running = false;
        } else {
            //state.running = false;
            reject('Wrong command in queue');
        }

        state.running = false;

        if (state.queue.length != 0) {
            runQueue(state, resolve, reject);
        } else {
            //console.log('stop vibrate!');
            await stopVibrate(state);
            console.log('Queue empty');
            return resolve(true);
        }
    } catch (ex) {
        state.running = false;
        reject(ex);
    }
};

export const run = async (state: State) => {
    await new Promise((r, j) => runQueue(state, r, j));
};
