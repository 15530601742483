import { createApp, markRaw } from 'vue';
import { createPinia } from 'pinia';

import * as Sentry from "@sentry/vue";
import { BrowserTracing } from "@sentry/tracing";
import VueGoogleMaps from '@fawmi/vue-google-maps';

import App from './app.vue';
import router from './router';

import './styles/main.scss';
import i18n from './translations';

import filters from './filters';

import { useAuthStore } from '@/stores/authentication';
import { useSessionStore } from '@/stores/session';
import { useChatStore } from '@/stores/chat';

const app = createApp(App);
const pinia = createPinia();
app.config.globalProperties.$filters = filters;

app.use(pinia);
app.use(router);
app.use(i18n);
app.use(VueGoogleMaps, {
    load: {
        key: 'AIzaSyDsg1RiizJy-bggV4JCMel-43VI9tVfB0c',
        libraries: 'places'
    },
});

pinia.use(({ store }) => {
    store.router = markRaw(router);
});

if (!import.meta.env.DEV){
    Sentry.init({
        app,
        dsn: "https://de12ab7645257390ee3b93e1db65a43d@o228560.ingest.sentry.io/4505781546713088",
        integrations: [
        new BrowserTracing({
            routingInstrumentation: Sentry.vueRouterInstrumentation(router),
            tracePropagationTargets: ["localhost", "my-site-url.com", /^\//]
        })
        ],
        tracesSampleRate: 0.1,
        release: '2d842f161ac35441fae3eb53f7e9a864fd4e8329'
    });
}

app.mount('#app');

startup();

async function startup() {
    // Listen socket session/chat
    useAuthStore().run();
    useSessionStore().initialize();
    useChatStore().initialize();
}
