import io from 'socket.io-client';
import type { LovenseClient } from './ontology/lovense';
import { randomNumber } from './utils';

export interface QrInformation {
    ackId: number;
    qrcode: string;
    qrcodeUrl: string;
}

export interface OnlineInformation {
    status: number;
}

export class LovenseSocketClient {
    private _url: string;
    private _path: string;
    private _socket: SocketIOClient.Socket | undefined;
    private _debug: boolean = false;
    private connectionStatus: 'disconnected' | 'connecting' | 'connected' = 'disconnected';
    private messageQueue: { event: string; content: string }[];
    private ackId: number = 0;

    private qrHandler?: (info: QrInformation) => void;
    private onlineHandler?: (info: OnlineInformation) => void;
    private statusHandler?: (client: OnlineInformation) => void;
    private deviceHandler?: (client: LovenseClient) => void;

    constructor(url: string, path: string, qrHandler?: (info: QrInformation) => void, onlineHandler?: (info: OnlineInformation) => void, deviceHandler?: (client: LovenseClient) => void, statusHandler?: (info: OnlineInformation) => void, debug: boolean = false) {
        this._url = url;
        this._path = path;
        this.messageQueue = [];
        this.debug = debug;
        this.qrHandler = qrHandler;
        this.onlineHandler = onlineHandler;
        this.deviceHandler = deviceHandler;
        this.statusHandler = statusHandler;
    }

    get debug() {
        return this._debug;
    }

    set debug(val: boolean) {
        this._debug = val;
    }

    public connect() {
        if (!this._socket) {
            this.ackId = 0;
            const options = {
                path: this._path,
                reconnection: true,
                reconnectionDelay: 5000,
                reconnectionDelayMax: 10000,
                transports: ['websocket'],
                forceNew: false
            };

            if (this.isConnected()) {
                throw new Error('[LovenseSocket] You already have an active lovense socket connection. Close it before proceeding');
            }

            this.connectionStatus = 'connecting';
            this.debug && console.log(this._url, this._path);
            this._socket = io.connect(this._url, options);

            this._socket.on('connect', this.socketConnect.bind(this));
            this._socket.on('disconnect', this.socketDisconnect.bind(this));
            this._socket.on('basicapi_get_qrcode_tc', this.socketQRCode.bind(this));
            this._socket.on('basicapi_update_device_info_tc', this.socketUpdateDevice.bind(this));
            this._socket.on('basicapi_update_app_online_tc', this.socketAppOnline.bind(this));
            this._socket.on('basicapi_update_app_status_tc', (result: any) => {
                this.socketStatusOnline(result);
            });
            this.getQR();
        }
    }

    disconnect() {
        if (!this._socket) {
            this.debug && console.debug('[LovenseSocket] Disconnecting socket disallowed');
            return;
        }

        this._socket.removeAllListeners();
        this._socket.disconnect();
        this.connectionStatus = 'disconnected';
        this._socket = undefined;
        console.log('Disconnected socket of lovense');
    }

    isConnected(): boolean {
        if (!this._socket) {
            return false;
        }
        return this._socket.connected;
    }

    private socketDisconnect(reason: string) {
        this.connectionStatus = 'disconnected';

        console.info(`[LovenseSocket] Disconnect with reason: ${reason}`);
    }

    private socketConnect() {
        this.connectionStatus = 'connected';
        console.info(`[LovenseSocket] Connected`);
    }

    private socketQRCode(result: any) {
        const qrResponse = JSON.parse(result);
        // console.info(`[LovenseSocket] qr information data`, qrResponse.data);
        //let resData = result ? JSON.parse(result) : {};
        if (qrResponse.data) {
            //this emit to
            this.debug && console.debug(`[LovenseSocket] qr information`, qrResponse);
            if (this.qrHandler) {
                this.qrHandler({
                    ackId: this.ackId,
                    qrcode: qrResponse.data.qrcode,
                    qrcodeUrl: qrResponse.data.qrcodeUrl
                });
            }
        }
    }

    private socketUpdateDevice(result: any) {
        const deviceInformation = JSON.parse(result);
        if (deviceInformation) {
            const client = deviceInformation as LovenseClient;
            if (this.deviceHandler) {
                this.deviceHandler(client);
            }
        }
    }

    private socketAppOnline(result: any) {
        const appOnline = JSON.parse(result);
        //console.log('online', result);
        if (appOnline) {
            if (this.onlineHandler) {
                this.onlineHandler({
                    status: appOnline.status
                });
            }
            this.debug && console.debug('[LovenseSocket] AppOnline', appOnline);
        }
    }

    private socketStatusOnline(result: any) {
        const appOnline = JSON.parse(result);
        //console.log('status', result);
        if (appOnline) {
            if (this.statusHandler) {
                this.statusHandler({
                    status: appOnline.status
                });
            }
            this.debug && console.debug('[LovenseSocket] StatusOnline', appOnline);
        }
    }

    public getQR() {
        if (!this._socket) {
            throw new Error('[LovenseSocket] getQR -> Lovense socket not connected');
        }

        //this.ackId = randomNumber(10000, 99000);
        this.ackId++;
        this._socket.emit('basicapi_get_qrcode_ts', {
            ackId: this.ackId
        });
    }

    sendCommand(message: any) {
        if (!this._socket) {
            throw new Error('[LovenseSocket] sendCommand -> Lovense socket not connected');
        }

        this._socket.emit('basicapi_send_toy_command_ts', message);
    }
}
