const config = {
    BaseUrl: enved('BaseUrl'),
    FullApiUrl: enved('FullApiUrl'),
    SocketUrl: enved('SocketUrl'),
    Themename: enved('Themename'),
    ImageUrl: enved('ImageUrl'),
    GoogleTagCode: enved('GoogleTagCode'),
    lovenseToken: enved('lovenseToken'),
    lovensePlatform: enved('lovensePlatform'),
    DefaultLanguage: enved('DefaultLanguage'),
    Version: enved('Version')
};

export default config;

function enved(key: string): string {
    return import.meta.env[`VITE_${key}`] || '';
}


if(window.loadTagManager){
    window.loadTagManager(window, document, 'script', 'dataLayer', config.GoogleTagCode);
}