import { getAuthenticationToken, getSocketUrl, getToysLocal } from '@/api/lovense';
import { DEFAULT_SPEED, checkDisable, type State, setOnline, setStatus } from './state';
import { LovenseSocketClient, type OnlineInformation, type QrInformation } from '@/socket.lovense';
import { startCheckLocalTimer, startTimer, stopCheckLocalTimer, stopTimer } from './timers';

import { setClient } from './toy';
import type { LovenseClient, LovenseDevice } from '@/ontology/lovense';

export const CHECK_LOCAL_CONNECT_INTERVAL = 10;

export const initSocket = async (state: State) => {
    console.info('[Lovense] Connecting socket');
    if (!state.performer) {
        throw Error(`Failed tot get performer token`);
    }

    //disconnect old socket make new one ?
    if (state.socket) {
        state.socket.disconnect();
    }

    //until socket is authenticated try to connect to the localconnection
    startCheckLocalTimer(state, CHECK_LOCAL_CONNECT_INTERVAL);

    const authToken = await getAuthenticationToken(state.performer.id);
    if (authToken) {
        const response = await getSocketUrl(authToken);
        if (response.error) {
            throw Error(`Error:  ${response.error.code} msg: ${response.error.message}`);
        }

        state.socketConnectionInfo = {
            socketIoUrl: response.socketIoUrl,
            socketIoPath: response.socketIoPath
        };

        state.debug && console.log('Go socket connection info', state.socketConnectionInfo);

        if (!state.socket || !state.socket.isConnected()) {
            state.socket = new LovenseSocketClient(
                state.socketConnectionInfo.socketIoUrl,
                state.socketConnectionInfo.socketIoPath,
                (qrInfo: QrInformation) => {
                    setQRUrl(state, qrInfo);
                },
                (online: OnlineInformation) => {
                    setOnline(state, online);
                },
                (client: LovenseClient) => {
                    setClient(state, client);
                },
                (online: OnlineInformation) => {
                    setStatus(state, online);
                }
            );
        }

        await state.socket.connect();
        state.status = 'waiting_for_qr';
    } else {
        throw Error(`Failed tot get authentication token`);
    }
};

export const disconnectSocket = (state: State) => {
    if (state.socket) {
        state.socket.disconnect();
    }

    state.socket = undefined;
};

export const getQrCode = (state: State) => {
    state.socket?.getQR();
};

export const convertToToyList = (list: any) => {
    const toyList: LovenseDevice[] = [];
    for (const index in list) {
        const toy = list[index] as LovenseDevice;
        toy.connected = (toy as any).status != 0;
        toy.toyType = toy.name?.toLowerCase();
        toyList.push(toy);
    }

    return toyList;
};
export const toggleConnection = async (state: State, isLocal: boolean) => {
    state.isLocal = !state.isLocal;
};
export const connectLocal = async (state: State) => {
    //console.info('[Lovense] Connecting local');
    const resp = await getToysLocal();
    if (resp.error) {
        stopTimer(state);
        console.info('[Lovense] Connect local failed');
        return false;
    }

    state.isLocal = true;

    state.status = 'connected';
    //console.info('[Lovense] Connected local');
    const parsed = JSON.parse(resp.data.toys);

    const client = {
        appVersion: '1.0.0',
        deviceId: 'x',
        domain: '127-0-0-1.lovense.club',
        httpPort: 30010,
        httpsPort: 30010,
        platform: resp.data.platform,
        toyJson: resp.data.toys,
        toyList: convertToToyList(parsed),
        wsPort: 0,
        wssPort: 0
    } as LovenseClient;

    setClient(state, client);
    //checkDisabled(state);
    state.debug && console.debug('Local client is', state.client);

    //stop local timer
    stopCheckLocalTimer(state);

    //start default timer
    startTimer(state);

    return true;
};

export const disconnectLocal = (state: State) => {
    stopCheckLocalTimer(state);
    stopTimer(state);

    if (state.isLocal && state.status === 'connected') {
        state.status = 'disconnected';
    }
};

//handlers
export const setQRUrl = (state: State, qrInfo: QrInformation) => {
    try {
        state.debug && console.log('Setting information...', qrInfo);
        state.QRurl = qrInfo.qrcodeUrl;
    } catch (ex) {
        console.log(ex);
    }
};
